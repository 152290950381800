import React, { useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Crackle from "./assets/images/crackle.png";
import PreludeToCrack from "./assets/images/prelude-to-crack.png";
import MF from "./assets/video/mf.mp4";

const GlobalStyles = createGlobalStyle`
  html, body, #crack {
    width: 100%;
    position: relative;
    height: 100%
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #crack {
    background-image: url(${PreludeToCrack});
    background-size: cover;
    background-position: center center;
  }

  * {
    font-family: 'Oswald';
    box-sizing: border-box;
    cursor: url(${Crackle}), auto;

  }
`;

const Button = styled.button`
  position: fixed;
  bottom: 5rem;
  left: 50%;
  width: 15rem;
  padding: 0.75rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
  font-weight: 900;
  border: none;
  outline: none;
  background-color: black;
  color: white;
  text-transform: uppercase;
  transform: translateX(-50%);
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")}


  &:focus,
  &:active {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 55vw;
    padding: 3vw;
    font-size: 5vw;
  }
`;

const Video = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
`;

function App() {
  const crack = useRef();
  const [isPlaying, setPlaying] = useState(false);

  const crackATize = React.useCallback(() => {
    crack.current.play();
  }, [crack]);

  return (
    <React.Fragment>
      <GlobalStyles />
      <Video
        src={MF}
        ref={crack}
        onPause={() => {
          setPlaying(false);
        }}
        onPlay={() => {
          setPlaying(true);
        }}
        onLoadedData={() => {
          crack.current.play();
        }}
      />
      <Button visible={!isPlaying} onClick={crackATize}>
        Click to Crack
      </Button>
    </React.Fragment>
  );
}

export default App;
